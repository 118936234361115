<template>
  <!-- Blur layer -->
  <div
    v-if="visible"
    class="blur-layer fixed z-blur-background backdrop-blur-xs w-full h-full"
  ></div>

  <!-- Hello bar -->
  <LayoutHelloBar v-if="showHelloBar"></LayoutHelloBar>

  <div
    class="logo w-full h-[56px] flex items-center justify-center fixed bg-white z-header"
  >
    <NuxtLink to="/">
      <img
        :src="`/images/theme/${themeGroup}/logo_mobil_240829.svg`"
        class="h-[35px]"
        :alt="logoAlt"
      />
    </NuxtLink>
  </div>

  <!-- First level menu items -->
  <div
    class="first-level-menu-items fixed w-full z-header overflow-y-auto"
    :class="{ expanded: expanded && activeToolboxItem === 1 }"
  >
    <div
      v-for="menuItem in mainStore.menuItems"
      :key="menuItem.name"
      class="menu-item flex items-center"
    >
      <div class="menu-item-icon flex justify-center">
        <template v-if="menuItem.url">
          <NuxtLink
            :to="menuItem.url"
            :title="menuItem.name"
            @click="handleMenuItemClick(menuItem.url)"
          >
            <img
              v-if="isIconImage(menuItem.icon)"
              :src="getResizedImageUrl(menuItem.icon, 25)"
              :alt="menuItem.name"
            />
            <i
              v-else-if="isIconFont(menuItem.icon)"
              :class="menuItem.icon"
              class="text-2xl"
            ></i>
          </NuxtLink>
        </template>
        <template v-else>
          <button
            type="button"
            :aria-label="menuItem.name"
            @click="showSecondLevelMenu(menuItem)"
          >
            <img
              v-if="isIconImage(menuItem.icon)"
              :src="getResizedImageUrl(menuItem.icon, 25)"
              :alt="menuItem.name"
            />
            <i
              v-else-if="isIconFont(menuItem.icon)"
              :class="menuItem.icon"
              class="text-2xl"
            ></i>
          </button>
        </template>
      </div>
      <div class="menu-item-text flex justify-between">
        <template v-if="menuItem.url">
          <NuxtLink
            :to="menuItem.url"
            :title="menuItem.name"
            @click="handleMenuItemClick(menuItem.url)"
            >{{ menuItem.name }}</NuxtLink
          >
        </template>
        <template v-else>
          <button
            type="button"
            :aria-label="menuItem.name"
            @click="showSecondLevelMenu(menuItem)"
          >
            {{ menuItem.name }}
          </button>
        </template>

        <template v-if="menuItem.items">
          <button
            type="button"
            :aria-label="menuItem.name"
            @click="showSecondLevelMenu(menuItem)"
          >
            <i class="m-icon-arrow_breadcrumb_right_blk text-xl"></i>
          </button>
        </template>
      </div>
    </div>
  </div>

  <!-- Second level menu items -->
  <div
    class="second-level-menu-items fixed w-full z-header overflow-y-auto"
    :class="{ expanded: currentMenuLevel === 2 && activeToolboxItem === 1 }"
  >
    <div class="navigation flex items-center justify-between">
      <button :aria-label="$t('back')" @click="hideSecondLevelMenu">
        <i class="m-icon-arrow_left text-2xl"></i>
      </button>
      <button :aria-label="$t('back')" @click="hide">
        <i class="m-icon-cancel text-2xl"></i>
      </button>
    </div>
    <div v-if="secondLevelAllProductsUrl" class="menu-item">
      <div class="menu-item-text h-full flex justify-between items-center">
        <NuxtLink
          :to="secondLevelAllProductsUrl"
          @click="handleMenuItemClick(secondLevelAllProductsUrl)"
        >
          {{ $t("all_products") }}
        </NuxtLink>
      </div>
    </div>
    <div
      v-for="menuItem in secondLevelMenuItems"
      :key="menuItem.name"
      class="menu-item"
    >
      <div class="menu-item-text h-full flex justify-between items-center">
        <template v-if="menuItem.url">
          <NuxtLink
            :to="menuItem.url"
            :title="menuItem.name"
            @click="handleMenuItemClick(menuItem.url)"
            >{{ menuItem.name }}</NuxtLink
          >
        </template>
        <template v-else>
          <button
            :aria-label="menuItem.name"
            type="button"
            @click="showThirdLevelMenu(menuItem)"
          >
            {{ menuItem.name }}
          </button>
        </template>

        <template v-if="menuItem.items">
          <button
            :aria-label="menuItem.name"
            type="button"
            @click="showThirdLevelMenu(menuItem)"
          >
            <i class="m-icon-arrow_breadcrumb_right_blk text-xl"></i>
          </button>
        </template>
      </div>
    </div>
  </div>

  <!-- Third level menu items -->
  <div
    class="third-level-menu-items fixed w-full z-header overflow-y-auto"
    :class="{ expanded: currentMenuLevel === 3 && activeToolboxItem === 1 }"
  >
    <div class="navigation flex items-center justify-between">
      <button :aria-label="$t('back')" @click="hideThirdLevelMenu">
        <i class="m-icon-arrow_left text-2xl"></i>
      </button>
      <button :aria-label="$t('back')" @click="hide">
        <i class="m-icon-cancel text-2xl"></i>
      </button>
    </div>
    <div v-if="thirdLevelAllProductsUrl" class="menu-item flex items-center">
      <div class="menu-item-text">
        <NuxtLink
          :to="thirdLevelAllProductsUrl"
          @click="handleMenuItemClick(thirdLevelAllProductsUrl)"
        >
          {{ $t("all_products") }}
        </NuxtLink>
      </div>
    </div>
    <div
      v-for="menuItem in thirdLevelMenuItems"
      :key="menuItem.name"
      class="menu-item flex items-center"
    >
      <div class="menu-item-text">
        <template v-if="menuItem.url">
          <NuxtLink
            :to="menuItem.url"
            :title="menuItem.name"
            @click="handleMenuItemClick(menuItem.url)"
            >{{ menuItem.name }}</NuxtLink
          >
        </template>
        <template v-else>
          <button :aria-label="menuItem.name" type="button">
            {{ menuItem.name }}
          </button>
        </template>
      </div>
    </div>
  </div>

  <!-- Account panel: we now use separate page for it instead of modal -->
  <!--
  <div
    v-if="expanded && activeToolboxItem === 2"
    class="account-panel fixed w-full z-header overflow-y-auto bottom-[168px] bg-white text-16 p-[20px]"
  >
    <ProfileMobileBase />
  </div>
    -->

  <!-- Cart panel -->
  <div
    v-if="expanded && activeToolboxItem === 3"
    class="cart-panel fixed w-full z-header overflow-y-auto bottom-[93px] bg-white text-16 p-[20px]"
  >
    <BasketBase />
  </div>

  <!-- Searchbox -->
  <div
    class="search w-full fixed bg-white z-header"
    :class="{ expanded: expanded }"
  >
    <div class="search-bar relative">
      <SearchBox />
    </div>
  </div>

  <!-- Toolbox -->
  <div
    class="toolboxWrap w-full fixed bg-white z-header"
    :class="{ expanded: expanded }"
  ></div>
  <div
    class="toolbox w-full fixed bg-white- z-toolbox"
    :class="{ expanded: expanded }"
  >
    <div
      class="toolbox-icons flex items-center justify-evenly rounded-full bg-brand-search text-white"
      :class="toolboxIconsClasses"
    >
      <button
        type="button"
        :aria-label="$t('menu')"
        @mousedown="activeToolboxItem = 1"
      >
        <i class="m-icon-menu text-2xl"></i>
      </button>
      <button
        type="button"
        :aria-label="$t('profile.profile')"
        @mousedown="activeToolboxItem = 2"
      >
        <i class="m-icon-user text-2xl"></i>
      </button>
      <button
        type="button"
        :aria-label="$t('cart')"
        @mousedown="activeToolboxItem = 3"
        @mouseup="showCart"
      >
        <i class="m-icon-cart text-2xl relative">
          <span
            v-if="cartItemsNumber > 0"
            class="absolute h-[16px] w-[16px] bg-brand-highlight text-xs text-white rounded-full right-[-10px] top-[-5px]"
            >{{ cartItemsNumber }}</span
          >
        </i>
      </button>
      <div></div>
    </div>
  </div>

  <button
    class="hamburger-button fixed flex items-center justify-center rounded-full z-toolbox text-brand-surface"
    :aria-label="$t('menu')"
    :class="{ 'bg-brand-search': !expanded, expanded: expanded }"
    @click="visible ? hide() : show()"
  >
    <i
      class="text-2xl"
      :class="{ 'm-icon-cancel': visible, 'm-icon-menu': !visible }"
    ></i>
  </button>
</template>

<script setup>
import { useMainStore } from "~/stores/main";
import { useCartStore } from "~/stores/cartStore";
import { viewCart as sendViewCartEvent } from "~/composables/useGoogleAnalytics";

// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const mainStore = useMainStore();
const cartStore = useCartStore();
const route = useRoute();
const router = useRouter();
const { isIconFont, isIconImage, getResizedImageUrl } = useHelpers();

// -----------------------
// reactive properties
// -----------------------
const expanded = ref(false);
const visible = ref(false);
const activeToolboxItem = ref(1); // 1: Menu, 2: Account, 3: Cart
const smoothSlide = ref(false);
const currentMenuLevel = ref(1);
const secondLevelMenuItems = ref([]);
const secondLevelAllProductsUrl = ref(null);
const thirdLevelMenuItems = ref([]);
const thirdLevelAllProductsUrl = ref(null);
const animationSpeed = 400;
const themeGroup = config.public.theme.group;

// -----------------------
// computed properties
// -----------------------
const showHelloBar = computed(() => {
  return route.path === "/" && themeGroup === "mountex";
});

const toolboxIconsClasses = computed(() => {
  let classList = "has-active";

  if (smoothSlide.value) {
    classList += " smooth-slide";
  }

  return classList + " active-item-" + activeToolboxItem.value;
});

const cartItemsNumber = computed(() => {
  if (typeof cartStore.cartData === "undefined") {
    cartStore.fetchCartData();
  }
  if (cartStore.cartData?.products?.length)
    return cartStore.cartData?.products?.length;
  return 0;
});

const logoAlt = computed(() => {
  if (config.public.theme.group === "tnf") return "The North Face webáruház";
  return "Mountex";
});

// -----------------------
// helper functions
// -----------------------
const show = (activeItem = 1) => {
  activeToolboxItem.value = activeItem;
  smoothSlide.value = false;
  expanded.value = true;

  setTimeout(() => {
    visible.value = true;
  }, animationSpeed + 100);
};

const hide = () => {
  expanded.value = false;
  currentMenuLevel.value = 1;
  secondLevelMenuItems.value = [];
  thirdLevelMenuItems.value = [];
  secondLevelAllProductsUrl.value = null;
  thirdLevelAllProductsUrl.value = null;

  setTimeout(() => {
    activeToolboxItem.value = 1;
    visible.value = false;
    mainStore.cartVisible = false;
  }, animationSpeed + 100);
};

const showSecondLevelMenu = (menuItem) => {
  if (!menuItem.items) {
    return;
  }

  if (menuItem.url) {
    secondLevelAllProductsUrl.value = menuItem.url;
  }

  secondLevelMenuItems.value = menuItem.items;
  currentMenuLevel.value = 2;
};

const hideSecondLevelMenu = () => {
  secondLevelAllProductsUrl.value = null;
  secondLevelMenuItems.value = [];
  currentMenuLevel.value = 1;
};

const showThirdLevelMenu = (menuItem) => {
  if (!menuItem.items) {
    return;
  }

  if (menuItem.url) {
    thirdLevelAllProductsUrl.value = menuItem.url;
  }

  thirdLevelMenuItems.value = menuItem.items;
  currentMenuLevel.value = 3;
};

const hideThirdLevelMenu = () => {
  thirdLevelAllProductsUrl.value = null;
  thirdLevelMenuItems.value = [];
  currentMenuLevel.value = 2;
};

const showCart = () => {
  mainStore.cartVisible = true;
  sendViewCartEvent();
};

const handleMenuItemClick = (targetUrl) => {
  if (route.path === targetUrl) {
    hide();
  }
};

// -----------------------
// vue events
// -----------------------
watch(activeToolboxItem, (newValue, oldValue) => {
  if (newValue !== null && oldValue !== null) {
    smoothSlide.value = true;
  } else {
    smoothSlide.value = false;
  }
  if (newValue === 2) {
    const profilePath = `/${config.public.routes.profile}`;

    if (route.path === profilePath) {
      // Already on the page
      hide();
    } else {
      router.push(`/${config.public.routes.profile}`);
    }
  }
  if (activeToolboxItem === 3) {
    mainStore.cartVisible = true;
  }
});

watch(expanded, (newValue) => {
  if (newValue) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});

watch(() => route.path, hide);

watch(
  () => route.path,
  (newPath) => {
    showHelloBar.value = newPath === "/";
  },
);

watch(
  () => mainStore.cartVisible,
  (cartVisible) => {
    if (cartVisible) {
      show(3);
    } else {
      hide();
    }
  },
);
</script>

<style scoped>
@keyframes switch-icons {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hello-bar {
  position: fixed;
  height: 28px;
}

.logo {
  top: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.first-level-menu-items,
.second-level-menu-items,
.third-level-menu-items {
  top: 108px;
  left: -100%;
  height: calc(100% - 201px);
  padding: 0 20px 20px 20px;
  transition: left ease-in-out 400ms;
  background-color: white;
  font-size: 16px;
}

.account-panel,
.cart-panel {
  max-height: calc(100% - 201px);
}

.first-level-menu-items.expanded,
.second-level-menu-items.expanded,
.third-level-menu-items.expanded {
  left: 0;
}

.menu-item {
  height: 38px;
}

.second-level-menu-items .menu-item,
.third-level-menu-items .menu-item {
  padding: 0 38px 0 32px !important;
}

.first-level-menu-items .menu-item {
  transition: margin-left ease-in 400ms;
}

.first-level-menu-items .menu-item:hover {
  margin-left: 10px;
}

.menu-item-icon {
  flex: 0 0 82px;
}

.menu-item-icon img,
.menu-item-icon i {
  width: 25px;
}

.first-level-menu-items .menu-item-text {
  flex: 1 0 auto;
}

.menu-item-text {
  transition: color ease-in 500ms;
}

.menu-item:hover .menu-item-text {
  color: #b27c56;
}

.second-level-menu-items .navigation,
.second-level-menu-items .menu-item,
.third-level-menu-items .navigation,
.third-level-menu-items .menu-item {
  padding: 0 32px;
  height: 38px;
  margin-bottom: 10px;
}

.navigation {
  margin-top: -50px;
  transition: margin-top ease-in-out 750ms;
}

.second-level-menu-items.expanded .navigation,
.third-level-menu-items.expanded .navigation {
  margin-top: 0;
}

.navigation img {
  width: 25px;
  height: 25px;
}

.search {
  left: -100%;
  top: 56px;
  height: 52px;
  padding: 6px 16px;
  opacity: 0;
  transition: all ease-in-out 400ms;
}

.home-page .search {
  top: 84px;
}

.home-page.theme-group-tnf .search {
  top: 56px;
}

.search.expanded {
  left: 0;
  opacity: 1;
}

.search input {
  width: 100%;
  border-radius: 20px;
  padding: 8px 50px 8px 19px;
}

.search button {
  position: absolute;
  right: 31px;
  top: 23px;
  width: 35px;
  height: 30px;
}

.toolboxWrap {
  bottom: 0;
  left: -100%;
  height: 93px;
  padding: 0 15px 18px 15px;
  transition: left ease-in-out 400ms;
}

.toolboxWrap.expanded {
  left: 0;
}

.toolbox {
  bottom: 0;
  left: 100%;
  height: 93px;
  padding: 0 15px 18px 15px;
  transition: left ease-in-out 400ms;
}

.toolbox.expanded {
  left: 0;
}

.toolbox-icons > * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 75px;
}

.toolbox-icons {
  background-repeat: no-repeat;
}

.toolbox-icons.has-active {
  background-image: url("/icons/active-toolbox-item-background.svg");
}

.toolbox-icons.smooth-slide {
  transition: background-position ease-in-out 300ms;
}

.toolbox-icons.active-item-1 {
  background-position: calc(20% - 65px) -1px;
}

.toolbox-icons.active-item-2 {
  background-position: calc(40% - 22px) -1px;
}

.toolbox-icons.active-item-3 {
  background-position: calc(60% + 22px) -1px;
}

.hamburger-button {
  width: 75px;
  height: 75px;
  right: calc(20% - 50px);
  bottom: 18px;
}

.hamburger-button img {
  width: 25px;
  height: 25px;
}

.hamburger-button.expanded img {
  animation-name: switch-icons;
  animation-duration: 500ms;
}

.hamburger-button.expanded {
  color: white;
}

.home-page .logo {
  top: 28px;
}

.theme-group-tnf.home-page .logo {
  top: 0 !important;
}

.home-page .first-level-menu-items,
.home-page .second-level-menu-items,
.home-page .third-level-menu-items {
  top: 136px;
  height: calc(100% - 229px);
}

.theme-group-tnf.home-page .first-level-menu-items,
.theme-group-tnf.home-page .second-level-menu-items,
.theme-group-tnf.home-page .third-level-menu-items {
  top: 108px;
  height: calc(100% - 201px);
}

.home-page .account-panel,
.home-page .cart-panel {
  max-height: calc(100% - 229px);
}

.theme-group-tnf.home-page .account-panel,
.theme-group-tnf.home-page .cart-panel {
  max-height: calc(100% - 201px);
}
</style>
