<template>
  <div
    class="usp-bar grid justify-evenly justify-items-center"
    :class="
      themeGroup === 'mountex'
        ? 'grid-cols-3 bg-brand-surface'
        : 'grid-cols-1 bg-black text-white'
    "
  >
    <div class="md:col-span-1 md:col-start-1 sm:col-span-full">
      <i
        class="m-icon-free_delivery text-[28px] mr-4 relative top-[6px]"
        :alt="$t('usp_bar_text_1')"
      ></i>
      {{ $t("usp_bar_text_1") }}
    </div>
    <div
      v-if="themeGroup === 'mountex'"
      class="md:col-span-1 md:col-start-2 sm:col-span-full"
    >
      <i
        class="m-icon-mountain text-[28px] mr-4 relative top-[6px]"
        :alt="$t('usp_bar_text_2')"
      ></i>
      {{ $t("usp_bar_text_2") }}
    </div>
    <div
      v-if="themeGroup === 'mountex'"
      class="md:col-span-1 md:col-start-3 sm:col-span-full"
    >
      <i
        class="m-icon-delivery text-[28px] mr-4 relative top-[6px]"
        :alt="$t('usp_bar_text_3')"
      ></i>
      {{ $t("usp_bar_text_3") }}
    </div>
  </div>
</template>
<script setup>
// -----------------------
// composables
// -----------------------
const config = useRuntimeConfig();
const themeGroup = config.public.theme.group;
</script>
