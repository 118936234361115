<template>
  <LayoutMobileHeader v-if="mainStore.isMobile"></LayoutMobileHeader>
  <LayoutDesktopHeader
    v-if="!mainStore.isMobile"
    @show-profile-modal="isProfileModalVisibleRef = true"
  ></LayoutDesktopHeader>

  <ProfileModal
    :show="isProfileModalVisibleRef"
    @close="isProfileModalVisibleRef = false"
  ></ProfileModal>
</template>

<script setup>
import ProfileModal from "~/components/Profile/ProfileModal.vue";
import { useMainStore } from "~/stores/main";

// -----------------------
// props & emits
// -----------------------
const config = useRuntimeConfig();

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();

// -----------------------
// plugins
// -----------------------
// const { $api } = useNuxtApp();

// -----------------------
// reactive properties
// -----------------------
const isProfileModalVisibleRef = ref(false);

// -----------------------
// init logic
// -----------------------
// console.time("fetchMenu"); // Start timing
/*
const { data: menuItems } = await useAsyncData("menuItems_CK", () =>
  $api(`cmspage-menu/${config.public.mainMenuId}`),
);
*/
const menuItems = await $fetch(`/api/cmspageMenu`, {
  params: { id: config.public.mainMenuId },
});
// console.timeEnd("fetchMenu"); // End timing and print the elapsed time

mainStore.menuItems = menuItems;
</script>
