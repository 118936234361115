<script setup>
import { useMainStore } from "~/stores/main";

// -----------------------
// props & emits
// -----------------------
const props = defineProps({
  show: {
    type: Boolean,
    required: true,
  },
  delayedHide: {
    type: Boolean,
  },
});

const emit = defineEmits(["hide"]);

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const route = useRoute();

// -----------------------
// reactive properties
// -----------------------
const menuRef = ref(null);

// -----------------------
// computed properties
// -----------------------
const computedClasses = computed(() => {
  const classes = ["level-" + mainStore.menuLevelsNumber];

  if (props.show) {
    classes.push("menu-visible");
  }

  if (route.path === "/" && mainStore.headerVisibilityState === "show") {
    classes.push("more-top");
  }

  return classes.join(" ");
});

// -----------------------
// helper functions
// -----------------------
const hideMenu = () => {
  menuHideId = null;
  mainStore.deselectMenu();
  emit("hide");
};

const hideMenuWithDelay = () => {
  lastMenuHide = Date.now();

  menuHideId = setTimeout(() => {
    if (Date.now() - lastMenuHide >= hideMenuDelay) {
      hideMenu();
    }
  }, hideMenuDelay);
};

const hideMenuImmediately = () => {
  hideMenu();
};

const clearMenuHide = () => {
  if (menuHideId !== null) {
    clearTimeout(menuHideId);
  }
};

// -----------------------
// vue events
// -----------------------
watch(
  () => props.delayedHide,
  (newValue) => {
    if (newValue === true) {
      hideMenuWithDelay();
    } else if (newValue === false) {
      clearMenuHide();
    }
  },
);

// -----------------------
// init logic
// -----------------------

// Hide menu immediately if user clicks outside of menu
document.body.addEventListener(
  "click",
  (e) => {
    let el = e.target;

    while (el.tagName.toLowerCase() !== "body") {
      if (el === menuRef.value || el.classList.contains("hamburger-button")) {
        return;
      }

      el = el.parentNode;
    }

    emit("hide");
  },
  true,
);

const hideMenuDelay = 400;
let lastMenuHide = Date.now();
let menuHideId = null;
</script>

<template>
  <div
    ref="menuRef"
    class="menu-container absolute z-header"
    :class="computedClasses"
    @mouseenter="clearMenuHide"
    @mouseleave="hideMenuWithDelay"
  >
    <LayoutDesktopMenuLevel
      :level="3"
      @leave-page="hideMenuImmediately"
    ></LayoutDesktopMenuLevel>
    <LayoutDesktopMenuLevel
      :level="2"
      @leave-page="hideMenuImmediately"
    ></LayoutDesktopMenuLevel>
    <LayoutDesktopMenuLevel
      :level="1"
      @leave-page="hideMenuImmediately"
    ></LayoutDesktopMenuLevel>
  </div>
</template>

<style scoped>
.menu-container {
  top: 73px;
}

.menu-container.more-top {
  top: 173px;
}

.theme-group-tnf .menu-container.more-top {
  top: 123px;
}

.menu-container {
  opacity: 0;
  transition-duration: 200ms;
  transition-property: left, opacity;
  transition-timing-function: ease-out;
}

.menu-container.level-1 {
  left: -328px;
}

.menu-container.level-2 {
  left: -656px;
}

.menu-container.level-3 {
  left: -984px;
}

.menu-visible {
  opacity: 1;
  left: 0 !important;
}
</style>
