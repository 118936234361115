<template>
  <template v-for="item in structuredContent" :key="JSON.stringify(item)">
    <CmsPageContentBlock
      v-bind="{
        block: item,
      }"
    />
  </template>
</template>
<script lang="ts" setup>
const props = defineProps<{
  id: string;
}>();

const token = ref();

$fetch(`/api/contentElement`, {
  params: { id: props.id, type: "token" },
}).then((tokenData) => {
  token.value = tokenData;
});

const structuredContent = computed(() => {
  if (token.value?.data?.data?.content?.pages) {
    return token.value.data.data.content.pages[0].frames[0].component
      .components;
  }
});
</script>
