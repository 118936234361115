<script lang="ts" setup>
import BaseModal from "~/components/Base/BaseModal.vue";

// -----------------------
// composables
// -----------------------

// -----------------------
// props & emits
// -----------------------
withDefaults(
  defineProps<{
    show?: boolean;
  }>(),
  {
    show: true,
  },
);

const emit = defineEmits(["close"]);

// -----------------------
// reactive properties
// -----------------------

// -----------------------
// computed properties
// -----------------------

// -----------------------
// helper functions
// -----------------------
const closeModal = () => {
  emit("close");
};
</script>

<template>
  <BaseModal :width="1000" :show="show" :autoclose="false" @close="closeModal">
    <div class="flex flex-col items-center">
      <div>
        <img
          src="/images/cart-merge.png"
          class="mb-5"
          alt=""
          style="height: 91px"
        />
      </div>
      <div class="m-5 text-3xl font-bold">{{ $t("cartmerge.title") }}</div>
      <div class="m-2 text-center">{{ $t("cartmerge.block1") }}</div>
      <div class="m-5 text-center">{{ $t("cartmerge.block2") }}</div>
      <div class="mt-5">
        <BaseButton type="primary" class="mr-4" @click="closeModal"
          >{{ $t("continue_shopping") }}
        </BaseButton>
      </div>
    </div>
  </BaseModal>
</template>
