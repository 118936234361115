<script lang="ts" setup>
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { AisInstantSearch } from "vue-instantsearch/vue3/es";

const props = withDefaults(
  defineProps<{
    index?: string;
  }>(),
  {
    index: "",
  },
);

const config = useRuntimeConfig();

const host = config.public.meilisearchHost;
const searchApiKey = config.public.meilisearchKey;
const options = { finitePagination: true, primaryKey: "id" };
const searchComponentVisible = ref(true);

const { searchClient } = instantMeiliSearch(host, searchApiKey, options);
</script>

<template>
  <AisInstantSearch
    v-if="searchComponentVisible"
    :index-name="`${props.index}:type:asc`"
    :search-client="searchClient"
    :future="{
      preserveSharedStateOnUnmount: false,
      persistHierarchicalRootCount: false,
    }"
  >
    <slot name="default" />
  </AisInstantSearch>
</template>
