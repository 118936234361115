<script lang="ts" setup>
import BaseModal from "~/components/Base/BaseModal.vue";

// -----------------------
// composables
// -----------------------

// -----------------------
// props & emits
// -----------------------
withDefaults(
  defineProps<{
    show?: boolean;
  }>(),
  {
    show: true,
  },
);

const emit = defineEmits(["close"]);

// -----------------------
// reactive properties
// -----------------------

// -----------------------
// computed properties
// -----------------------

// -----------------------
// helper functions
// -----------------------
const closeModal = () => {
  emit("close");
};
</script>

<template>
  <BaseModal :width="1000" :show="show" :autoclose="false" @close="closeModal">
    <ProfileBase />
  </BaseModal>
</template>
