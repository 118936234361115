<script lang="ts" setup>
import {
  AisAutocomplete,
  // AisHighlight,
  AisConfigure,
} from "vue-instantsearch/vue3/es";
import { useMainStore } from "~/stores/main";

// -----------------------
// composables
// -----------------------
const mainStore = useMainStore();
const config = useRuntimeConfig();
const { productPageUrl, getResizedImageUrl } = useHelpers();

// -----------------------
// refs
// -----------------------
const searchBoxDiv = ref(null);
const isOpen = ref(false);
const searchTerm = ref("");

// -----------------------
// methods
// -----------------------
const productImage = (item) => {
  if (item.image) {
    return getResizedImageUrl(item.image, null, 100);
  }
};

const productUrl = (hit: Record<string, any>) => {
  return productPageUrl({
    name: hit.name,
    sku: hit.url,
    url_code: hit.url_code,
  });
};

const redirectToSearchPage = () => {
  if (searchTerm.value) {
    isOpen.value = false;
    location.href = `/search?query=${searchTerm.value}`;
  }
};

const searchClasses = computed(() => {
  const classes: string[] = [
    "w-full",
    "h-[40px]",
    "border",
    "border-brand-search",
    "focus:outline-brand-primary",
    "placeholder-brand-body",
  ];

  if (config.public.theme.group === "mountex") {
    classes.push("lg:border-none");
  }

  return classes.join(" ");
});
// -----------------------
// vue events
// -----------------------
onMounted(() => {
  document.addEventListener("click", handleSearchBoxClickOutside);
});

onUnmounted(() => {
  document.removeEventListener("click", handleSearchBoxClickOutside);
});

const handleSearchBoxClickOutside = (event) => {
  if (searchBoxDiv.value && !searchBoxDiv.value.contains(event.target)) {
    isOpen.value = false;
  }
};
</script>

<template>
  <SearchBoxProvider
    v-if="config.public.meilisearchIndexSearch"
    :index="`${config.public.meilisearchIndexSearch}`"
  >
    <ais-configure :hits-per-page.camel="6" />
    <ais-autocomplete>
      <template #default="{ currentRefinement, indices, refine }">
        <div ref="searchBoxDiv" class="relative">
          <input
            type="search"
            :value="currentRefinement"
            :placeholder="$t('search')"
            :class="searchClasses"
            style="outline-offset: 0px"
            @input="
              isOpen = true;
              searchTerm = $event.currentTarget.value;
              refine($event.currentTarget.value);
            "
            @keydown.enter="redirectToSearchPage"
          />
          <button
            class="bg-brand-search flex items-center justify-center hover:shadow-md transition-shadow duration-300 ease-in-out"
            :aria-label="$t('search')"
            :title="$t('search')"
            @click="redirectToSearchPage"
          >
            <i class="m-icon-search text-18"></i>
          </button>
          <div v-if="currentRefinement && isOpen">
            <ul v-for="index in indices" :key="index.indexId">
              <li v-if="index.hits?.length">
                <ul
                  :class="mainStore.isMobile ? 'top-full' : ''"
                  class="absolute overflow-y-auto bg-white border mr-5 ml-5 px-3"
                >
                  <li v-for="(hit, hitIndex) in index.hits" :key="hit.id">
                    <a
                      v-if="hit.type === '20_masterproduct'"
                      :href="productUrl(hit)"
                      class="flex"
                    >
                      <div
                        :class="hitIndex > 0 ? 'border-t' : ''"
                        class="w-full grid grid-cols-12 py-2 items-center text-sm"
                      >
                        <div
                          class="col-span-9 pr-3 h-full flex hover:underline items-center underline-offset-2"
                        >
                          {{ hit.name }}
                        </div>
                        <div class="col-span-3 text-right">
                          <img
                            v-if="productImage(hit)"
                            :src="productImage(hit)"
                          />
                        </div>
                      </div>
                    </a>

                    <a
                      v-if="hit.type === '10_cmspage'"
                      :href="`${hit.url}`"
                      class="flex"
                    >
                      <div
                        :class="hitIndex > 0 ? 'border-t' : ''"
                        class="w-full grid grid-cols-12 py-2 items-center text-sm"
                      >
                        <div
                          class="col-span-9 pr-3 flex items-center h-full hover:underline underline-offset-2"
                        >
                          {{ hit.name }}
                        </div>
                        <div class="col-span-3 text-center text-[40px]">
                          <i v-if="hit.icon" :class="hit.icon"></i>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </ais-autocomplete>
  </SearchBoxProvider>
</template>
